@media screen and (min-width: 1024px) {
  .header {
    border-bottom: 0;

    .header-layout {
      max-width: 1440px;
      padding: 24px;
      margin: 0 auto;
    }

    .logo {
      width: 152px;
      height: 58px;
    }

    .launch-button {
      width: 138px;
      height: 47px;
      line-height: 47px;
      font-size: 16px;
    }

    .nav-icon {
      display: none;
    }
  }

  .nav {
    display: none;
  }

  .footer {
    display: block;
    position: fixed;
    z-index: 201;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0 50px 50px;

    .footer-layout {
      max-width: 1440px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
    }

    .footer-nav {
      display: flex;

      a {
        color: #ffffff;
        margin-right: 42px;
        font-weight: 600;
        opacity: .6;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .section__layout {
    max-width: 1440px;
    margin: 0 auto;
    padding: 120px 200px 120px !important;
  }

  .full-page-slider-pagination {
    display: flex;
    position: fixed;
    top: 0;
    right: 68px !important;
    z-index: 110;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .full-page-slider-pagination__link {
    position: relative;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;
    margin: 20px 0 !important;
    display: flex !important;
    height: initial !important;
    background-color: transparent !important;
    font-size: 14px;
    opacity: .5;
    color: #ffffff;

    &.swiper-pagination-bullet-active {
      opacity: 1;

      &:after {
        opacity: 1;
      }
    }

    &:after {
      content: '';
      width: 2px;
      height: 2px;
      background-color: #ffffff;
      opacity: 1;
      border-radius: 50%;
    }

    &.swiper-pagination-bullet-active:after {
      border-radius: 1px;
      width: 17px;
    }
  }

  .mobile-pagination {
    display: none;
  }
}

// @media screen and(min-width: 960px) {
//   display: flex;
// }
